
import * as types from './actions'


const initialState = {
    locations: {},
    request: {loading: false,
                success: false},
    mqtt: {}
}

export default function ilb(state = initialState, action={}) {

    switch(action.type){

        case types.DEPLOYMENT_REQUEST:
            return {...state, request: {loading: true, success: false}}

        case types.DEPLOYMENT_SUCCESS:
            var locations = state.locations
            locations[action.meta.panID] = action.payload
            return {...state, request: {loading: false, success: true}}

        case types.DEPLOYMENT_FAILURE:
            return {...state, request: {loading: false, success: false}}

        case types.GET_LOCATION_LIST:
            var locations = state.locations
            locations[action.panID] = action.locations
            return Object.assign({}, state, {locations: locations})

        case types.UPDATE_TOPIC:
            var mqtt = state.mqtt
            const split = action.topic.split("/")
            const panId = Number(split[3].substring(7))
            const spec = 's' + split[4]
            const type = split[5]

            const rCode = split.length > 6 ? split[6] : undefined



            if(!(panId in state.mqtt)){
                let ret = {'s1': {'L2_LASTSEEN': {}},
                                's2': {'L2_LASTSEEN': {}}}
                if(rCode){
                    ret[spec][type][rCode] = action.message
                }else{
                    ret[spec][type] = action.message
                }
                return {...state, mqtt: {...state.mqtt, [panId]: ret}}


            }else {
                let ret = state.mqtt[panId]
                if(rCode){
                    ret[spec][type][rCode] = action.message
                }else{
                    ret[spec][type] = action.message
                }

                return {...state, mqtt: {...state.mqtt, [panId]: ret}}
            }


/*        case types.UPDATE_TOPIC:
            var mqtt = state.mqtt
            const split = action.topic.split("/")
            const panId = Number(split[4].substring(7))
            const spec = 's' + split[5]
            const type = split[6]

            const rCode = split.length > 7 ? split[7] : undefined

            if(!(panId in mqtt)){
                mqtt[panId] = {'s1': {'L2_LASTSEEN': {}},
                                's2': {'L2_LASTSEEN': {}}}
            }

            if(rCode){
                mqtt[panId][spec][type][rCode] = action.message
            }else{
                mqtt[panId][spec][type] = action.message
            }
            
            return Object.assign({}, state, {mqtt: mqtt})*/

        default:
            return state
    }
}


export function getLocations(state){
    const info = state.ilb.locations
    return info
}

export function getRequestState(state){
    const info = state.ilb.request
    return info
}


export function getTopics(state, panId){
    const info = state.ilb.mqtt[panId]
    return info
}
