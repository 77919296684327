import React, { Component } from 'react'
import { select } from 'd3-selection'

const WIDTH = 70
const HEIGHT = 50

const MARGIN = {
    left: 25,
    top: 5,
    right: 0,
    bottom: 15
}


class Gas extends Component {

    constructor(props){
    super(props)
        this.state = {
            clicked: false
        }
    }
    componentDidMount(){

        const INNER_WIDTH = WIDTH - MARGIN.left - MARGIN.right
        const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom

        var svg = select(this.node).style("cursor", "pointer")
        var group = svg.append("g").attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")
                        .style("pointer-events", "none")

        group.append("rect").attr("class", "gas-proto")
            .attr("width", INNER_WIDTH).attr("height", INNER_HEIGHT)

        group.append("text").attr("transform", "translate(" + INNER_WIDTH/2 + "," + (5+ (INNER_HEIGHT/2)) + ")")
            .attr("class", "gas-label").text(this.props.gas)

        group.append("text").attr("class", "proto-label limit1").text(this.props.limit[1])
        group.append("text").attr("y", INNER_HEIGHT).attr("class", "proto-label limit0").text(this.props.limit[0])
        group.append("text").attr("y", INNER_HEIGHT).attr("class", "proto-label time0").text(this.props.timeRange[0])
        group.append("text").attr("y", INNER_HEIGHT).attr("x", INNER_WIDTH)
            .attr("class", "proto-label time1").text(this.props.timeRange[1])

    }

    clicked(){

        this.props.clickFunc(this.props.gas, this.props.prod)
        this.setState((prevState) => {
          return {clicked: !prevState.clicked}
        })
    }

    render(){
        if(this.state.clicked || this.props.active){
        return (<svg ref={node => this.node = node} className="selected-gas gas-svg"
         width={WIDTH} height={HEIGHT} onClick={this.clicked.bind(this)}></svg>)
        }else {
        return (<svg ref={node => this.node = node} className="gas-svg"
         width={WIDTH} height={HEIGHT} onClick={this.clicked.bind(this)}></svg>)
        }
    }

}

const headings = {  'tot': "Total columns (DU)",
                    'trop': "Tropospheric columns (DU)",
                    'surfc': "Surface concentrations (ppb)"}

class GasBlock extends Component{


    render(){
        const gases = this.props.gases
        var block = []
        for (const prod in gases) {
            var row = []
            row.push(<p className="prod-label">{headings[prod]}</p>)
            for (const gas in gases[prod]){
                row.push(<Gas active={this.props.active} key={gas+'_'+prod} gas={gas} timeRange={this.props.timeRange} 
                    limit={gases[prod][gas]} prod={prod} clickFunc={this.props.clickFunc}/>)
            }
            block.push(row)
        }

        return(
            <div className="gas-block">
            <h1>Products</h1>
                {block}
            <p className="menu-axes-info">x-axes are local time</p>
            </div>
            )

    }
}

class Heading extends Component{

    componentDidMount(){
        var svg = select(this.node)
        svg.append("line").attr("x1", 0).attr("y1", this.props.height).attr("x2", this.props.width).attr("y2", this.props.height)
            .style("stroke", "black").style("stroke-width", 2)
        svg.append("text")
        .attr("font-size", "12px")
        .attr("y",10)
        .text(this.props.text)
    }

    render(){
        return(
            <svg ref={node => this.node = node} className={this.props.classy} width={this.props.width} height={this.props.height}>
            </svg>)
    }
}

class GasRow extends Component{


    render(){
        const gases = this.props.gases
        var row = []
        for (const prod in gases) {
            for (const gas in gases[prod]){
                row.push(<Gas active={this.props.active} key={gas+'_'+prod} gas={gas} timeRange={this.props.timeRange} 
                    limit={gases[prod][gas]} prod={prod} clickFunc={this.props.clickFunc}/>)
            }
        }

        return(
            <div>
            <div className="gas-headings">
            <Heading width="355" height="20" text={headings['tot']} classy="tot" />
            <Heading width="204" height="20" text={headings['trop']} classy="trop" />
            <Heading width="209" height="20" text={headings['surfc']} classy="surfc" />
            </div>
            <div className="gas-row">
                {row}
            </div>
            </div>
            )

    }
}

export {
    GasBlock,
    GasRow,
}

