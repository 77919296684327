import * as types from './actionTypes'
import {union, flatten} from 'underscore'
import { LIMIT } from './constants'

const initialState = {
  globalDay: null,
  allDays: [],
  dayList: {},
  detailLocation: [null, null],
  detailDay: null,
  stationList: {},
  selectedStations: [],
  selectedGases: [],
  graphList: {},
  rowList: {},
}

export default function graphs(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHOW_DETAIL:
      if (state.detailLocation[0] === action.location[0] && state.detailLocation[1] === action.location[1])
      {
        return {
        ...state, 
        detailLocation:[null, null]
      };
      }else {
        return {
          ...state, 
          detailLocation: action.location,
          detailDay: action.day
        };
      }
    case types.UPDATE_DETAIL_DAY:
      return {...state,
              detailDay: action.day
            };
    case types.GRAPH_INIT:
      var graphList = state.graphList
      graphList[action.id] = action.params
      return Object.assign({}, state, {graphList: graphList})

    case types.STATIONS_INIT:
      return Object.assign({}, state, {stationList: action.stationList})

    case types.SELECT_STATIONS:
      return Object.assign({}, state, {selectedStations: action.stations})

    case types.SELECT_GASES:
      return Object.assign({}, state, {selectedGases: action.gases})

    case types.GET_DATA:
      var rowList = state.rowList
      var dayList = state.dayList
      //rowList[action.row[0]] = action.row[0] in rowList ? rowList[action.row[0]].concat(action.row[1]) : action.row[1]
      rowList[action.row[0]] = action.row[0] in rowList ? mergeData(rowList[action.row[0]], action.row[1]) : action.row[1]
      rowList[action.row[0]].sort(orderByType)


      dayList[action.row[0]] = mergeData(dayList[action.row[0]], action.days)

      //dayList[action.row[0]] = action.days



      var allDays = union(flatten(Object.values(dayList))).sort().reverse()

      return Object.assign({}, state,
        {graphList: Object.assign({}, state.graphList, action.graphList)},
        {rowList: rowList},
        {dayList: dayList},
        {allDays: allDays},
        {globalDay: state.globalDay ? state.globalDay : allDays[0]})

      case types.SELECT_GLOBAL_DAY:
        return Object.assign({}, state, {globalDay: action.day})
      
    default:
      return state;
  }
}

const ORDERING = {
  'tot': 0,
  'trop': 1,
  'surfc': 2,
  'NO2':0,
  'O3': 1,
  'SO2': 2,
  'HCHO': 3,
  'H2O': 4
}

function orderByType(a, b){
  var prod = ORDERING[a.split('_')[2]] - ORDERING[b.split('_')[2]]
  return prod === 0 ? ORDERING[a.split('_')[3]] - ORDERING[b.split('_')[3]] : prod
}

function orderByGas(a, b){
  var gas = ORDERING[a.split('_')[3]] - ORDERING[b.split('_')[3]]
  return gas === 0 ? ORDERING[a.split('_')[2]] - ORDERING[b.split('_')[2]] : gas
}

function mergeData(old, toAdd){
  if(old === undefined)
    old = []
  toAdd.forEach(function(d){
    if ( old.indexOf(d) < 0 ){
      old.push(d)
    }
  })
  return old
}



export function getGraphParams(state, id) {
  const info = state.graphs.graphList[id].params
  return info
}

export function getStationDetail(state, id) {
  const info = state.graphs.stationList[id]
  return info
}

export function getStationList(state) {
  const stations = state.graphs.stationList
  return stations
}


export function getGasList() {
  const gases = LIMIT
  return gases
}


export function getDetail(state) {
  const loc = state.graphs.detailLocation
  const day = state.graphs.detailDay
  return {location: loc, day: day}
}

export function getSelectedStations(state) {
  const info = state.graphs.selectedStations
  return info
}
export function getSelectedGases(state) {
  const info = state.graphs.selectedGases
  return info
}

export function getGraphs(state, rowID){
  const info = state.graphs.rowList[rowID] !== undefined ? state.graphs.rowList[rowID] : []
  return info
}

export function getRowIDs(state){
  const info = Object.keys(state.graphs.rowList)
  return info
}

export function getDays(state, rowID){
  const info = state.graphs.dayList[rowID] !== undefined ? state.graphs.dayList[rowID] : []
  return info
}

export function hasData(state){
  var hasData = {}
  for(var key in state.graphs.rowList){
    hasData[key] = state.graphs.rowList[key].length > 0
  }
  return hasData
}

export function getGlobalDay(state){
  const info = state.graphs.globalDay
  return info
}

export function getAllDays(state){
  const info = state.graphs.allDays
  return info
}
