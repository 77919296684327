import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as graphSelectors from '../store/graphs/reducer'
import { selectStations, showDetail } from '../store/graphs/actions'
import './Map.css'
import L from 'leaflet'
import './Terminator.js'


require('leaflet/dist/leaflet.css');

function formatPopup(location, panID){
    return `<span>${location}</span></br>
            <span>Instruments: ${panID}</span>`
}

class Map extends Component{

    constructor(props){
        super(props)
        this.handleSelection = this.handleSelection.bind(this)
        this.selfSet = false
        this.dontSelect = false
        this.markers = {}
    }


    componentDidMount(){
        const stationList = this.props.stationList

        var map = L.map("map").setView([17.1, 11.2], 1);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
        }).addTo(map)
		
	//L.mapbox.accessToken = 'pk.eyJ1IjoiYXhlbC1tYiIsImEiOiJEcUphODFJIn0.EUG2LN1I96bqyyFNhugtZQ';
	//	
	//	L.tileLayer('https://api.mapbox.com/styles/v1/axel-mb/cirq6ln1k003hgznc4rmztss8/tiles/{z}/{x}/{y}?access_token=' + L.mapbox.accessToken, {
	//		tileSize: 512,
	//		zoomOffset: -1,
	//		attribution: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	//		maxZoom: 18
	//	}).addTo(map);	
		
        //day night shadow
        L.terminator().addTo(map)
        

        var stations = []
        for (var s in stationList){
        stations.push(Object.assign(stationList[s], {id: s, LatLng: new L.LatLng(stationList[s].location.lat,stationList[s].location.lon)}));
        }

        var icon = L.icon({
            iconUrl: require('./mapicon.png'),
            iconSize: [15, 15],
            iconAnchor: [10, 10],
            popupAnchor: [10, 0],
            shadowSize: [0, 0],
            className: 'leaflet-marker'
        })

        var markers = {}
        stations.forEach(function(d){
            var marker = L.marker(d.LatLng, {icon: icon, title: d.name}).bindPopup(formatPopup(d.name, d.id)).addTo(map);
            markers[d.id] = marker
        })

        map.on("moveend", this.handleSelection);
        map.on("zoomend", this.handleSelection);

        this.map = map
        this.stations = stations
        this.markers = markers

    }

    componentDidUpdate(){
        if(this.selfSet){this.selfSet = false; return;}

        const bounds = this.map.getBounds()
        var visible = []
        this.stations.forEach(function(d){
            if (bounds.contains(d.LatLng)) visible.push(d.id)
        })

        if(!this.props.selectedStations.map(d=>visible.indexOf(d)>-1).every(d=>d)){
            this.dontSelect = true
            this.selfSet = true
            this.map.setView([47.1, 11.2], 1);
        }
    }

    handleSelection(){
        if(this.dontSelect){this.dontSelect=false;return;}
        const bounds = this.map.getBounds()
        var visible = []
        this.stations.forEach(function(d){
            if (bounds.contains(d.LatLng)) visible.push(d.id)
        })

        if(visible.length !== this.props.selectedStations.length 
            || !visible.map(d=>this.props.selectedStations.indexOf(d)>-1).every(d=>d)){

            this.selfSet = true
            this.props.dispatch(showDetail([null, null]))
            this.props.dispatch(selectStations(visible))
            
        }
    }

    render(){
        return (<div id="map"></div>)
    }
}

const mapStateToProps = (state) => {
  const stations = graphSelectors.getStationList(state)
  const selectedStations = graphSelectors.getSelectedStations(state)
  const hasData = graphSelectors.hasData(state)
  return {
    stationList: stations,
    selectedStations : selectedStations,
    hasData: hasData
  }
}

export default connect(mapStateToProps)(Map)

