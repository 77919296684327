import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import * as graphSelectors from '../store/graphs/reducer'
import { selectStations, selectGases, showDetail, selectGlobalDay, updateDetailDay} from '../store/graphs/actions'
import { GasBlock, GasRow } from '../components/GasBlock'
import StationList from '../components/StationList'
import { MenuDaybutton } from '../components/Daybutton'
import 'react-dropdown/style.css'

import './Menu.css'


class Menu extends Component {

    constructor(props){
        super(props)

        this.state = {
            stations: [],
            gases: []
        }

        autoBind(this)
    }

    componentWillMount(){
        this.props.dispatch(selectStations(Object.keys(this.props.stations)))
    }

    componentDidUpdate(){
        this.props.dispatch(selectGases(this.state.gases))
    }

    handleStationSelection(id){
        var stations = this.props.selectedStations.slice(0)
        const index = stations.indexOf(id)
        if (index < 0){ 
            stations = [].concat(this.props.selectedStations, id)
            
        }else if (stations.length === Object.keys(this.props.stations).length){
            stations = [id]
        } else{
            stations.splice(index, 1)
        }
        stations = stations.length > 0 ? stations : Object.keys(this.props.stations)
        this.props.dispatch(showDetail([null, null]))
        this.props.dispatch(selectStations(stations))
    }

    handleGasSelection(gas, prod){
        const gasString = prod + '_' + gas
        const gases = this.state.gases.slice(0)
        const index = gases.indexOf(gasString)
        if (index < 0){ 
            const gases = [].concat(this.state.gases, gasString)
            this.setState({gases})
        } else {
            gases.splice(index, 1)
            this.setState({gases})
        }
    }

 
    render () {
        const gasesActive = this.state.gases.length < 1 ? true : false
        const stationsActive = this.props.selectedStations.slice(0).map(d=>parseInt(d, 10))


        return (
        <div className="Menu" >
        <StationList active={stationsActive} stations={this.props.stations} clickFunc={this.handleStationSelection.bind(this)} />
        {/*<GasBlock active={gasesActive} gases={this.props.gases} timeRange={[2,22]} clickFunc={this.handleGasSelection.bind(this)}/>*/}
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  const stations = graphSelectors.getStationList(state)
  const gases = graphSelectors.getGasList()
  const selectedStations = graphSelectors.getSelectedStations(state)
  return {
    stations: stations,
    gases: gases,
    selectedStations: selectedStations
  }
}


export default connect(mapStateToProps)(Menu)
