import React, { Component } from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { showDetail} from '../store/graphs/actions'
import * as graphSelectors from '../store/graphs/reducer'

import './Graph.css'
import { scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'
import { select} from 'd3-selection'
import { line, curveBundle} from 'd3-shape'
import { axisTop, axisRight, axisLeft, axisBottom} from 'd3-axis'


import { Q_COLORS, FILL_OPACITY} from '../store/graphs/constants'



const margin = 15

const MARGIN = {
    top: 5,
    bottom: margin,
    left: margin,
    right: 5
}

class Graph extends Component {


  constructor(props) {
    super(props)

    this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
    this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom
    //this.yExtent = extent(this.props.data, d=>d[valueKey])
    //this.data = props.data.filter(d => d.loctime >= props.timeRange[0] && d.loctime <= props.timeRange[1])




    autoBind(this)
  }

   componentDidMount() {
      this.createGraph()
   }

   componentDidUpdate() {
      const node = this.node
      const svg = select(node).select("g")
      svg.remove()
      this.createGraph()
   }


  
    createGraph() {

    const limit = this.props.limit
    const valueKey = this.props.valueKey
    const data = this.props.data[this.props.currentDay]
    
    const node = this.node
    var svg = select(node)

    if(data === undefined){return }

    const hasGoodData = data.filter(d => d.L2dataQflag === 0).length > 1

    svg.append("rect")
            .attr("width",this.props.size[0]).attr("height",this.props.size[1])
            .attr("fill", "white")

    var group = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")

    var xScale = scaleLinear().range([0,this.WIDTH]).domain(this.props.timeRange).nice()
    var yScale = scaleLinear().range([this.HEIGHT, 0]).domain(this.props.limit)

    var yExtent = extent(data.filter(d=>d.L2dataQflag<2), d=>d[valueKey])
    if (yExtent[0] === undefined || yExtent[0] === yExtent[1]){
      yExtent = extent(data, d => d[valueKey])
    }
    const padding = (yExtent[1] - yExtent[0])*0.1
    yExtent[0] -= padding
    yExtent[1] += padding

    this.yExtent = yExtent


    //define axes as grid        
    var xGrid = axisTop().scale(xScale).tickSize(-this.HEIGHT).tickFormat('').ticks(5)
    var yGrid = axisRight().scale(yScale).tickSize(-this.WIDTH).tickFormat('').ticks(5)

    group.append("rect").attr("class","bkgrd")
            .attr("width",this.WIDTH).attr("height",this.HEIGHT)
    //ygrid
    group.append("g")
            .attr("transform", "translate("+(this.WIDTH)+",0)")
            .call(yGrid)
            .attr("class","grid y")
    //call xgrid    
    group.append("g")
            //.attr("transform", "translate(0," +MARGIN.top + ")")
            .call(xGrid)
            .attr("class","grid")



    var valueline = line()
                    .defined(d => d.L2dataQflag === 0)
                    .x(d => xScale(d.loctime))
                    .y(function(d, i) { if(d[valueKey] < limit[0]){
                                          return yScale(limit[0])
                                        } else if (d[valueKey] > limit[1]){
                                          return yScale(limit[1])
                                        }else{
                                          return yScale(d[valueKey])
                                        }}).curve(curveBundle)
                    

    //call xaxis
    group.append("g")
            .attr("transform", "translate(0," + this.HEIGHT + ")")
            .call(axisBottom(xScale).tickSize(0).ticks(5))
            .attr("class", "axis x")
            .style("font-size", "5px")
    //call yaxis
    group.append("g")
            .call(axisLeft(yScale).tickSize(0).ticks(5))
            .attr("class","axis y")
            .style("font-size", "5px")


/*    //add heading, graph label
    group.append("text")
        .attr("font-size", "12px")
        .attr("y", -MARGIN.top/7)
        .attr("class", "station-label")
        .text(this.props.label)*/

    group.append("defs").append("clipPath")
        .attr("id", "clip" + this.props.id)
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", this.WIDTH)
        .attr("height", this.HEIGHT)

    const main = group.append("g")
            .attr("clip-path", "url(#clip" + this.props.id + ")")

/*    main.append("path")
        .attr("class", "line")
        .attr("d", valueline(data))
        .classed('data-path', true)
        .attr("stroke", Q_COLORS[0])*/

    main.selectAll(".goodCircles")
      .data(data.filter(d => d.L2dataQflag === 0))
      .enter().append("circle")
      .attr("fill", Q_COLORS[0]).attr("class", "goodCircles")
      .attr("cx", function(d, i) { return xScale(d.loctime); })
         .attr("cy", function(d, i) { if(d[valueKey] < limit[0]){
                                          return yScale(limit[0])
                                        } else if (d[valueKey] > limit[1]){
                                          return yScale(limit[1])
                                        }else{
                                          return yScale(d[valueKey])
                                        }})
         .attr("r", function(d, i) { return 2; })


    //draw datapoints
    main.selectAll(".allCircles")
        .data(data)
        .enter().append("circle").attr("class", "allCircles")
         .attr("fill", d => Q_COLORS[d.L2dataQflag]).attr("fill-opacity", d=> FILL_OPACITY[d.L2dataQflag])
         .attr("cx", function(d, i) { return xScale(d.loctime); })
         .attr("cy", function(d, i) { if(d[valueKey] < limit[0]){
                                          return yScale(limit[0])
                                        } else if (d[valueKey] > limit[1]){
                                          return yScale(limit[1])
                                        }else{
                                          return yScale(d[valueKey])
                                        }})
         .attr("r", function(d, i) { return 1; })
         .style("opacity", d => hasGoodData ? 0 : FILL_OPACITY[d.L2dataQflag])


   }


    show(){

        const valueKey = this.props.valueKey
        const data = this.props.data[this.props.currentDay]
        if(data === undefined){return }
        var svg = select(this.node)
        var yScale = scaleLinear().range([this.HEIGHT, 0]).domain(this.yExtent)
        /*var xScale = scaleLinear().range([0,this.WIDTH]).domain(this.props.timeRange)*/
/*        var valueline = line()
                .defined(d => d.L2dataQflag < 1)
                .x(d => xScale(d.loctime))
                .y(d => yScale(d[valueKey]))*/

        //rescale
        svg.select(".y.axis").transition().call(axisLeft(yScale).tickSize(0).ticks(5))
        svg.select(".y.grid").transition().call(axisRight().scale(yScale).tickSize(-this.WIDTH).tickFormat('').ticks(5))

        svg.selectAll("path").transition().duration(50).style("opacity", 0)

        svg.selectAll(".allCircles").transition().attr("cy", function(d, i) { return yScale(d[valueKey]); }).style("opacity", 1)
        svg.selectAll(".goodCircles").transition().attr("cy", function(d, i) { return yScale(d[valueKey]); }).style("opacity", 0)
        //zoom + show axis
        svg.transition().attr("transform", "scale(2)")

        svg.selectAll(".axis,.grid,.axis-label").transition().style("opacity", 1)
        svg.selectAll(".bkgrd").transition().style("fill-opacity", 1).style("stroke-opacity", 0)
        //svg.select(".station-label").transition().style("opacity", 1).style("font-size", "9px")
        svg.selectAll(".data-label").transition().style("opacity", 0)

        svg.classed("ontop", true)

        select(this.node.nextSibling).style("z-index", -1)
        select(this.node.previousSibling).style("z-index", -1)

    }

   hide(){

        const limit = this.props.limit
        const valueKey = this.props.valueKey
        const data = this.props.data[this.props.currentDay]
        
        if(data === undefined){return }

        const hasGoodData = data.filter(d => d.L2dataQflag === 0).length > 1

        var svg = select(this.node)
        var yScale = scaleLinear().range([this.HEIGHT, 0]).domain(limit)
        /*var xScale = scaleLinear().range([0,this.WIDTH]).domain(this.props.timeRange)
        var valueline = line()
                .defined(d => d.L2dataQflag === 0)
                .x(d => xScale(d.loctime))
                .y(function(d, i) { if(d[valueKey] < limit[0]){
                                          return yScale(limit[0])
                                        } else if (d[valueKey] > limit[1]){
                                          return yScale(limit[1])
                                        }else{
                                          return yScale(d[valueKey])
                                        }}).curve(curveBundle)*/

        //rescale
        svg.select(".y.axis").transition().call(axisLeft(yScale).tickSize(0).ticks(5))
        svg.select(".y.grid").transition().call(axisRight().scale(yScale).tickSize(-this.WIDTH).tickFormat('').ticks(5))

        //svg.selectAll("path").classed("small", false)
        svg.selectAll("path").transition().style("opacity", 1)

        svg.selectAll(".allCircles").transition().attr("cy", function(d, i) { if(d[valueKey] < limit[0]){
                                          return yScale(limit[0])
                                        } else if (d[valueKey] > limit[1]){
                                          return yScale(limit[1])
                                        }else{
                                          return yScale(d[valueKey])
                                        }}).style("opacity", d => hasGoodData ? 0 : FILL_OPACITY[d.L2dataQflag])

        svg.selectAll(".goodCircles").transition().attr("cy", function(d, i) { return yScale(d[valueKey]); }).style("opacity", 1)

        //unzoom + hide axis
        svg.transition().attr("transform", "scale(1)")

        svg.selectAll(".axis,.grid").transition().style("opacity", 0)
        svg.selectAll(".bkgrd").transition().style("fill-opacity", 0.2).style("stroke-opacity", 1)
        //svg.select(".station-label").transition().style("opacity", 1).style("font-size", "12px")
        svg.selectAll(".axis-label").transition().style("opacity", 0)
        svg.selectAll(".data-label").transition().style("opacity", 1)
        
        svg.classed("ontop", false)

        select(this.node.nextSibling).style("z-index", 1)
        select(this.node.previousSibling).style("z-index", 1)
    }

    clicked(){
      const location = [this.props.rowIndex, this.props.id]
      this.props.dispatch(showDetail(location, this.props.currentDay))
    }



    render() {

      const data = this.props.data[this.props.currentDay]

      if(data === undefined) {return(<svg width={this.props.size[0]} height={this.props.size[1]} ref={node => this.node = node} />)}

      return(
          <svg ref={node => this.node = node} id={this.props.id} className="graph"
          width={this.props.size[0]} height={this.props.size[1]} onClick={this.clicked} onMouseEnter={this.show} onMouseLeave={this.hide}>
          </svg>)

      
   }

}

const mapStateToProps = (state, ownProps) => {
  const {gas, prod, limit, timeRange, valueKey, data, days} = graphSelectors.getGraphParams(state, ownProps.id)
  //const currentDay = graphSelectors.getRowDay(state, ownProps.rowID)
  return {
    limit: limit,
    timeRange: timeRange,
    label: gas + ' - ' + prod,
    valueKey: valueKey,
    data: data,
    days: days
  }
}


export default connect(mapStateToProps)(Graph)