import jwtDecode from 'jwt-decode'
import * as auth from './actions'


var initialState = {}
try{
  initialState = {
    access: JSON.parse(localStorage.getItem('ACCESS')),
    refresh: JSON.parse(localStorage.getItem('REFRESH')),
    user: JSON.parse(localStorage.getItem('USER')),
    errors: {},
  }
}catch(error){
  initialState = {
    access: null,
    refresh: null,
    user: null,
    errors: {},
  }
}

export default (state=initialState, action) => {
  switch(action.type) {
    case auth.LOGIN_SUCCESS:
      return {
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access)
        },
        refresh: {
          token: action.payload.refresh,
          ...jwtDecode(action.payload.refresh)
        },
        user: {name: action.meta.name},
        errors: {}
    }
    case auth.TOKEN_RECEIVED:
      return {
        ...state,
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access)
        }
      }
    case auth.LOGIN_FAILURE:
      return {
           access: undefined,
           refresh: undefined,
           user: undefined,
           errors: 
               action.payload.response || 
                  {'non_field_errors': action.payload.statusText},
        }

    case auth.TOKEN_FAILURE:
      return {
         access: undefined,
         refresh: undefined,
         user: undefined,
         errors: 
             action.payload.response || 
                {'non_field_errors': action.payload.statusText},
      }
    case auth.LOGOUT:
      return {
         access: undefined,
         refresh: undefined,
         user: undefined,
         errors: {}
      } 

    case auth.CLEAR_ERRORS:
      return {
        ...state, 
        errors: {}
      }
    default:
      return state
    }
}


export function accessToken(state) {
    if (state.auth.access) {
        return  state.auth.access.token
    }
}
    
export function refreshToken(state) {
    if (state.auth.refresh) {
        return  state.auth.refresh.token
    }
}
    
export function isAccessTokenExpired(state) {
  if (state.auth.access && state.auth.access.exp) {
    return 1000 * state.auth.access.exp - (new Date()).getTime() < 5000
  }
  return true
}
export function isRefreshTokenExpired(state) {
  if (state.auth.refresh && state.auth.refresh.exp) {
    return 1000 * state.auth.refresh.exp - (new Date()).getTime() < 5000
  }
  return true
}
export function isAuthenticated(state) {
  return !isRefreshTokenExpired(state)
}
export function authErrors(state) {
   return  state.auth.errors
}

export function getUser(state){
    const ret = state.auth.access ? {name: state.auth.user.name, id: state.auth.access.user_id} : state.auth.user
    return ret
}

export function withAuth(headers={}) {
  return (state) => ({
    ...headers,
    'Authorization': `Bearer ${accessToken(state)}`
  })
}
