import React, { Component } from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import Row from './Row'
import Detail from './Detail'
import {DETAIL_SIZE, REFRESH_TIMER} from '../store/graphs/constants.js'
import './GraphBlock.css'
import { getData } from '../store/graphs/actions'
import * as graphSelectors from '../store/graphs/reducer'



class GraphBlock extends Component {

  constructor(props){
    super(props)
    autoBind(this)
  }

  init(){
    for (const id in this.props.stations){
      this.props.dispatch(getData(id, this.props.stations[id].name, 's1', 'tot'))
      this.props.dispatch(getData(id, this.props.stations[id].name, 's1', 'trop'))
      this.props.dispatch(getData(id, this.props.stations[id].name, 's1', 'surfc'))
    }
  }

  componentWillMount(){
    this.init()
    this.timerID = setInterval(this.init, REFRESH_TIMER)
  }

  componentWillUnmount(){
    clearInterval(this.timerID)
  }


   render(){
      var graphBlock = []
      for (var i=0;i<this.props.selectedStations.length;i++){
        const stationID = this.props.selectedStations[i]
        const rowIndex = i
        const rowID = stationID + '_s1'
        const station = {id: stationID, name: this.props.stations[stationID].name}
        graphBlock.push(<Row key={rowIndex} rowIndex={rowIndex} rowID={rowID} station={station}/>)

      }
      if (this.props.detailLocation[0] !== null){
        
         graphBlock.splice(this.props.detailLocation[0]+1, 0,
            <Detail key={9999} id={this.props.detailLocation[1]} size={DETAIL_SIZE}/>
            )
      } 

      return (<div className='graphBlock'>{graphBlock}</div>)
   }
} 


function mapStateToProps(state) {
  const {location} = graphSelectors.getDetail(state)
  const stations = graphSelectors.getStationList(state)
  const selectedStations = graphSelectors.getSelectedStations(state)
  const rowIDs = graphSelectors.getRowIDs(state)
  return {
    detailLocation: location,
    selectedStations: selectedStations,
    stations: stations,
    rowIDs: rowIDs
  }
}


export default connect(mapStateToProps)(GraphBlock)
