import { connect } from 'mqtt';
import { withAuth } from '../auth/reducer';
import { DJANGO_URL, MQTT } from '../graphs/constants';

import { RSAA } from 'redux-api-middleware';

export const DEPLOYMENT_REQUEST = 'DEPLOYMENT_REQUEST';
export const DEPLOYMENT_SUCCESS = 'DEPLOYMENT_SUCCESS';
export const DEPLOYMENT_FAILURE = 'DEPLOYMENT_FAILURE';


export const postLocationsByID = (panID, content) => ({
  [RSAA]: {
    endpoint: DJANGO_URL + 'locations/' + panID,
    method: 'POST',
    body: content,
    headers: withAuth({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }),
    types: [
      DEPLOYMENT_REQUEST,
      { type: DEPLOYMENT_SUCCESS, meta: { panID: panID } },
      DEPLOYMENT_FAILURE
    ]
  }
})


export const GET_LOCATION_LIST = 'GET_LOCATION_LIST'

export function getLocationsByID(panID) {
  return (dispatch) => {
    fetch(DJANGO_URL + 'locations/' + panID + '.json')
      .then(response => response.json())
      .then(json => dispatch(getLocationsCallback(json, panID)))
  }
}

function getLocationsCallback(json, panID) {

  var locations = json['data']

  return { type: GET_LOCATION_LIST, panID: panID, locations: locations }
}


export const UPDATE_TOPIC = 'UPDATE_TOPIC'

export function subscribeToMqtt() {


  return (dispatch) => {
    if (!MQTT) { return }
    var client = connect('wss://ovh.pandonia-global-network.org:443/mqtt', { username: 'webstatus', password: 'JovTegOid8' });

    const topic = 'Pandora/monitoring/live/#'
    client.on('connect', () => {
      client.subscribe(topic, null)
    })


    client.on('message', (topic, message) => {
      dispatch({ type: UPDATE_TOPIC, topic: topic, message: JSON.parse(message.toString()) })
    })

    //setTimeout(()=>{client.end()}, 10000)

  }

}


