import { RSAA } from 'redux-api-middleware';
import { DJANGO_URL } from '../graphs/constants'
export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';
export const CLEAR_ERRORS = '@@auth/CLEAR_ERRORS';
export const LOGOUT = '@@auth/LOGOUT'


export const clearErrors = () => ({type: CLEAR_ERRORS})
export const logout = () =>({type: LOGOUT})

export const login = (username, password) => ({
  [RSAA]: {
    endpoint: DJANGO_URL + 'api/token/obtain',
    method: 'POST',
    body: JSON.stringify({username, password}),
    headers: { 'Content-Type': 'application/json' },
    types: [
      LOGIN_REQUEST, {type: LOGIN_SUCCESS, meta: {name: username}}, LOGIN_FAILURE
    ]
  }
})
export const refreshAccessToken = (token) => ({
  [RSAA]: {
    endpoint: DJANGO_URL + 'api/token/refresh',
    method: 'POST',
    body: JSON.stringify({refresh: token}),
    headers: { 'Content-Type': 'application/json' },
    types: [
      TOKEN_REQUEST, TOKEN_RECEIVED, TOKEN_FAILURE
    ]
  }
}) 
