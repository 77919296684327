import React, { Component } from 'react'
import autoBind from 'react-autobind'
import { select} from 'd3-selection'


const WIDTH = 100
const HEIGHT = 20
const PADDING = 3

class DayBar extends Component {

    constructor(props){
        super(props)
        autoBind(this)
    }

    componentDidMount(){
        this.createSVG()
    }

    componentDidUpdate() {
      const node = this.node
      const svg = select(node).select("g")
      svg.remove()
      this.createSVG()
   }

    createSVG(){
        var svg = select(this.node)

        var group = svg.append("g")

        group.append("rect").attr("width", WIDTH).attr("height", HEIGHT).attr("fill", "lightgrey")

        var num_days = this.props.allDays.length
        const allDays = this.props.allDays.slice(0)
        allDays.sort()
        const days = this.props.days.slice(0)
        days.sort()


        for(var i=0;i<num_days;i++){
            if(days.indexOf(allDays[i]) > -1){
                group.append("rect").attr("width", WIDTH/num_days - PADDING).attr("height", HEIGHT - PADDING)
                .attr("x", i*WIDTH/num_days + PADDING/2).attr("y",PADDING/2).attr("fill", "#0A73F7")
            }


            if(allDays[i] === this.props.globalDay){
                group.append("rect").attr("width", WIDTH/num_days-PADDING).attr("height", HEIGHT - PADDING)
                .attr("x", i*WIDTH/num_days + PADDING/2).attr("y",PADDING/2)
                .attr("fill", "none").attr("stroke-width", 1.5).attr("stroke", "red")
            }
        }
    }

    render(){
        return(<svg ref={node => this.node = node} className="day-bar"
          width={WIDTH} height={HEIGHT}>
          </svg>)
    }
}

export default DayBar
