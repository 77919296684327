import React, { Component } from 'react'
import { connect } from 'react-redux'
import './App.css'
import GraphBlock from './GraphBlock'
import Menu from './Menu'
import GasMenu from './GasMenu'
import Map from '../components/Map'
import { stationsInit } from '../store/graphs/actions'
import * as graphSelectors from '../store/graphs/reducer'


class App extends Component {

  componentWillMount(){
    this.props.dispatch(stationsInit())
  }

  componentDidMount(){
    document.title = "BlickV"
  }

   render() {
    if(Object.keys(this.props.stations).length === 0 )
      return null

   return (
      <div className="App-wrapper">
      <div className='App'>
      <div className='Header'>

      <Map />
      <Menu />
      </div>

      <GasMenu/>

      <GraphBlock/>
      
      <div className='fill-page' />

      </div>
      </div>
   )
   }
}

function mapStateToProps(state) {
  const stations = graphSelectors.getStationList(state)
  return {
    stations: stations,
  }
}


export default connect(mapStateToProps)(App)







