import * as types from './actionTypes'
import {LIMIT, TIME_RANGE, VALUE_KEY, AUX_LIMITS, AUX_THRESHOLDS, DJANGO_URL} from './constants'




/*
 * action creators
 */

export function showDetail(location, currentDay) {
  return { type: types.SHOW_DETAIL, location: location, day: currentDay}
}

export function updateDetailDay(currentDay) {
  return { type: types.UPDATE_DETAIL_DAY, day: currentDay}
}


export function getData(stationID, stationName, spec, prod){
  return (dispatch) => {
  const prodQuery = prod === 'surfc' ? 'trop' : prod
  fetch(process.env.PUBLIC_URL + '/data/' + stationID + '_' + spec + '_' + stationName + '_' + prodQuery + '.json')
    .then((response) => response.json())
      .catch(error=> {console.log(error)})
    .then((json) => {return dispatch(getDataCallback(json, stationID, stationName, spec, prod)) })
    .catch(error=>console.log(error))
  }
}


function getDataCallback(json, stationID, stationName, spec, prod){
  var graphList = {}
  var graphs = []
  var days = []
  for (const gas in LIMIT[prod]){

    //if(Object.keys(json[gas]).length === 0) continue

    const id = [stationID,spec,prod,gas].join('_')
    graphList[id] = {params: {station: stationID,
                              spec: spec,
                              limit: LIMIT[prod][gas],
                              timeRange: TIME_RANGE,
                              prod: prod,
                              gas: gas,
                              valueKey: VALUE_KEY[prod],
                              data: json[gas] || [],
                              days: Object.keys(json[gas] || []),
                              auxData: {
                                valueKey: [ VALUE_KEY[prod] + '_uncert', 'fitrms','wlshift', 'Tint', 'FW1', 'FW2'],
                                limits: AUX_LIMITS[gas],
                                thresholds: AUX_THRESHOLDS[gas]
                              }
                              }}
    graphs.push(id)
    if(days.length < Object.keys(json[gas] || []).length) days = Object.keys(json[gas] || [])
  }

  days.sort().reverse()
  const row = [[stationID,spec].join('_'), graphs]

  return {type: types.GET_DATA, row: row, graphList: graphList, days: days}

}

export function stationsInit(){
    return (dispatch) => {
    var list = {}
    fetch(DJANGO_URL + 'all_stations.json')
    .then((response) => response.json())
    .then((json) => {
      for (var s of json.data){
        list[s.panID] = {name: s.name, location: s.location}
    }
    return dispatch({type: types.STATIONS_INIT, stationList: list})
    })
  }
}

/*export function stationsInit(){
    var list = {}
    for (var s of all_stations.data){
        list[s.panID] = {name: s.name, location: s.location}
    }
    return {type: types.STATIONS_INIT, stationList: list}
}*/


export function selectStations(stations){
    return {type: types.SELECT_STATIONS, stations: stations}
}

export function selectGases(gases){
    return {type: types.SELECT_GASES, gases: gases}
}

export function selectGlobalDay(day){
    return {type: types.SELECT_GLOBAL_DAY, day: day}
}



 
