// export const DJANGO_URL = 'http://localhost:8000/'
export const DJANGO_URL = 'https://ovh.pandonia-global-network.org/'
export const MQTT = false //Disabled temporarily because MQTT server has not been setup at ovh
//export const DJANGO_URL = 'http://192.168.0.164:8000/'

//plot dimensions
export const GRAPH_SIZE = [150, 80]
export const DETAIL_SIZE = [1300, 650]

export const REFRESH_TIMER = 300000

export const NUM_PRODUCTS = 7

export const LIMIT = {
  tot: {
    NO2: [0, 2],
    O3: [250, 500],
    //SO2: [0,2],
    HCHO: [0, 2]
  },
  trop: {
    NO2: [0, 2],
    //O3: [0, 20],
    //SO2: [0,2],
    HCHO: [0, 2]
    //H2O: [0,2]
  },
  surfc: {
    NO2: [0, 20],
    //O3: [0, 100],
    //SO2: [0,100],
    HCHO: [0, 20]
    //H2O: [0,1000]
  }
}



export const VALUE_KEY = {
  tot: 'totcol',
  trop: 'tropcol',
  surfc: 'surfc'
}

export const TIME_RANGE = [2, 22]



export const AUX_LIMITS = {
  NO2: {
    uncert: [1e-4, 1e1],
    fitrms: [1e-4, 1e1],
    Tint: [1e0, 1e4],
    wlshift: [-.5, .5]
  },
  O3: {
    uncert: [1e-4, 5e2],
    fitrms: [1e-4, 1e1],
    Tint: [1e0, 1e4],
    wlshift: [-.5, .5]
  },
  SO2: {
    uncert: [1e-4, 5e1],
    fitrms: [1e-4, 1e1],
    Tint: [1e0, 1e4],
    wlshift: [-.5, .5]
  },
  HCHO: {
    uncert: [1e-4, 5e1],
    fitrms: [1e-4, 1e1],
    Tint: [1e0, 1e4],
    wlshift: [-.5, .5]
  },
  H2O: {
    uncert: [1e-4, 5e1],
    fitrms: [1e-4, 1e1],
    Tint: [1e0, 1e4],
    wlshift: [-.5, .5]
  }
}

export const AUX_THRESHOLDS = {
  NO2: {
    uncert: [4.8e-3, 1.33e-2],
    fitrms: [0.93e-3, 1.95e-3],
    wlshift: 2e-1
  },
  O3: {
    uncert: [1.0, 5.0],
    fitrms: [1e-2, 2e-2],
    wlshift: 2e-1
  },
  SO2: {
    uncert: [0.1, 0.3],
    fitrms: [1e-2, 2e-2],
    wlshift: 2e-1
  },
  HCHO: {
    uncert: [5e-2, 1e-1],
    fitrms: [1e-2, 2e-2],
    wlshift: 2e-1
  },
  H2O: {
    uncert: [5e-2, 1e-1],
    fitrms: [1e-2, 2e-2],
    wlshift: 2e-1
  }
}

export const COLORS = ['#1561D1', '#3E6AD2', '#5772D0', '#6C7BCD', '#7E84C7', '#8F8DC0', '#9F97B7', '#ADA1AC', '#BAAB9E']
//export const COLORS = ['#034498','#2C52A8','#4861B5','#6171BF','#7A81C5','#9391C8','#AAA3C7','#C2B5C3','#D8C7BA']
//export const COLORS = ['#0D3E6D','#274D7D','#3E5C8B','#546C97','#6C7BA0','#848CA7','#9C9CAB','#B5ADAD','#CDBEAB']

//export const Q_COLORS = [COLORS[0], COLORS[5], COLORS[8]]
export const Q_COLORS = [COLORS[0], COLORS[5], '#FFFFFF']
export const BKGRD_COLOR = '#EBEBEB'

export const FILL_OPACITY = [1, 0.8, 0.6]

export const LABELS = {
  'amf': 'AMF',
  'sza': 'SZA',
  'loctime': 'local time',
  'time': 'local time',
  'totcol': 'total column',
  'totcol_uncert': 'uncertainty',
  'L2dataQflag': 'quality flag',
  'L2fitQflag': 'quality-fit-flag',
  'fitrms': 'fit RMS',
  'wlshift': 'wl. shift',
  'Tint': 'T int.',
  'FW1': 'FW1',
  'FW2': 'FW2',
  'surfc': 'surface concentration',
  'surfc_uncert': 'uncertainty',
  'tropcol': 'tropospheric column',
  'tropcol_uncert': 'uncertainty'
}

export const UNITS = {
  'amf': ' ',
  'sza': 'deg',
  'loctime': ' ',
  'time': ' ',
  'totcol': 'DU',
  'totcol_uncert': 'DU',
  'L2dataQflag': ' ',
  'L2fitQflag': ' ',
  'fitrms': ' ',
  'wlshift': 'nm',
  'Tint': 'ms',
  'FW1': ' ',
  'FW2': ' ',
  'surfc': 'ppb',
  'surfc_uncert': 'ppb',
  'tropcol': 'DU',
  'tropcol_uncert': 'DU'
}


export const L2fit_DQ = {
  0: 'L1 data quality above 0',
  1: 'Spectral fitting not successful',
  2: 'Weighted RMS of spectral fitting too large',
  3: 'WLshift too large'
}

export const L1_DQ = {
  0: 'Saturated data',
  1: 'Too few dark count measurements',
  2: 'No temperature given or effective temp too different from reference',
  3: 'Dark count too high',
  4: 'Unsuccessful dark background fitting',
  5: 'Absolute value of estimated average residual stray light level too high',
  6: 'No WLshift could be retrieved',
  7: 'Absolute Value of WLshift too large',
  8: 'Retrieved WLshift differs too much from by effective temperature predicted WLshift'
}


export const STATUS_COLORS = {
  "laboratory": "#4c7fcc",
  "out of operation": "#997f7f",
  "hold due to issue": "#ccb24c",
  "intensive care": "#337f33",
  "testing": "#7f997f",
  "operational": "#7fcc33",
  "operational with issue": "#c7ea46",
  "maintenance": '#919191',
  "action by SciGlob needed": '#919191',
}

export const STATUS_SORTING = {
  "intensive care": 0,
  "operational": 1,
  "operational with issue": 1.5,
  "laboratory": 2,
  "maintenance": 3,
  "testing": 4,
  "out of operation": 5,
  "hold due to issue": 6,
  "action by SciGlob needed": 7,
  "None": 8
}

export const ISSUE_COLORS = {
  "hw.sh": "#e56666",
  "hw.rb": "#3399f2",
  "hw.cn": "#7f997f",
  "hw.fi": "#cc66b2",
  "sw.bp": "#7f99e5",
  "hw.pc": "#e56619",
  "hw.sp": "#e57f7f",
  "sw.bf": "#99bfb2",
  "hw.tr": "#e54c99",
  "sw.os": "#7f99cc",
  "sw.bm": "#4cb2b2",
  "hw.ps": "#e57f33",
  "hw.ap": "#4cb2b2",
  "hw.su": "#7f99e5",
  "sw.bo": "#72bfa5",
  "sw.rc": "#72b2cc",
  "hw.tc": "#597fe5",
  "unknown": "lightgrey"
}
