import React, { Component } from 'react'



class Station extends Component{
    constructor(props){
        super(props)
        this.clicked = this.clicked.bind(this)
    }

    clicked(){
        this.props.clickFunc(this.props.panID)
    }

    render(){
        const str = this.props.name + ' - ' + this.props.panID
        if(this.props.active) {
        return(
            <div className="menu-station-item selected-station" onClick={this.clicked}>
            <p>{str}</p>
            </div>
        )}
        else{
        return(
            <div className="menu-station-item" onClick={this.clicked}>
            <p>{str}</p>
            </div>
        )}            
    }
}

class StationList extends Component{

    render(){
        const stations = this.props.stations
        var stationList = []
        for (const id in stations){
            stationList.push(<Station active={this.props.active.indexOf(parseInt(id))>-1} key={id} panID={id} name={stations[id].name} clickFunc={this.props.clickFunc}/>)
        }

        return(<div className="menu-station-block">
            <h1>Stations</h1>
            <div className="menu-station-list">
            {stationList}
            </div>
            </div>)
    }
}

export default StationList
