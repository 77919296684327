import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import * as graphSelectors from '../store/graphs/reducer'
import { selectStations, selectGases, showDetail, selectGlobalDay, updateDetailDay} from '../store/graphs/actions'
import { GasBlock, GasRow } from '../components/GasBlock'
import StationList from '../components/StationList'
import { MenuDaybutton } from '../components/Daybutton'
import 'react-dropdown/style.css'

import './Menu.css'


class GasMenu extends Component {

    constructor(props){
        super(props)

        this.state = {
            gases: []
        }

        autoBind(this)
    }


    componentDidUpdate(){
        this.props.dispatch(selectGases(this.state.gases))
    }


    handleGasSelection(gas, prod){
        const gasString = prod + '_' + gas
        const gases = this.state.gases.slice(0)
        const index = gases.indexOf(gasString)
        this.props.dispatch(showDetail([null, null]))
        if (index < 0){ 
            const gases = [].concat(this.state.gases, gasString)
            this.setState({gases})
        } else {
            gases.splice(index, 1)
            this.setState({gases})
        }
    }

    selectDay(day){
        this.props.dispatch(selectGlobalDay(day.value))
    }

    dayLeft(){
        const dayIndex = this.props.days.indexOf(this.props.globalDay)
        if( dayIndex < this.props.days.length-1){
            const day = this.props.days[dayIndex+1]
            this.props.dispatch(selectGlobalDay(day))
        }
        
    }

    dayRight(){
        const dayIndex = this.props.days.indexOf(this.props.globalDay)
        if( dayIndex > 0){
            const day = this.props.days[dayIndex-1]
            this.props.dispatch(selectGlobalDay(day))
        }
    }
 
    render () {
        const gasesActive = this.state.gases.length < 1 ? true : false

        const dayIndex = this.props.days.indexOf(this.props.globalDay)
        const arrows = {left: dayIndex< this.props.days.length-1 ? true : false,
                        right: dayIndex > 0 ? true : false}

        return (
        <div className="menu-sticky">
        <GasRow active={gasesActive} gases={this.props.gases} timeRange={[2,22]} clickFunc={this.handleGasSelection.bind(this)} />
        <MenuDaybutton  globalDay={this.props.globalDay} allDays={this.props.days}
                          options={this.props.allDays} selectDay={this.selectDay}
                          clickLeft={this.dayLeft} clickRight={this.dayRight} arrows={arrows}/>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  const gases = graphSelectors.getGasList()
  const allDays = graphSelectors.getAllDays(state)
  const globalDay = graphSelectors.getGlobalDay(state)
  return {
    gases: gases,
    days: allDays,
    globalDay: globalDay
  }
}


export default connect(mapStateToProps)(GasMenu)
 
