import {createStore, combineReducers, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import apiMiddleware from './auth/refreshMiddleware'
import graphs from './graphs/reducer'
import ilb from './ilb/reducer'
import auth from './auth/reducer'

const reducer = combineReducers({
  graphs,
  ilb,
  auth
})

/*const startState = { auth: {
  access: JSON.parse(localStorage.getItem('ACCESS')),
  refresh: JSON.parse(localStorage.getItem('REFRESH')),
    errors: {},
}
}*/

const middleware = applyMiddleware(apiMiddleware, thunkMiddleware)
const store = createStore(reducer, composeWithDevTools(middleware))
//const store = createStore(reducer, middleware)

store.subscribe(() =>{
  localStorage.setItem('ACCESS', JSON.stringify(store.getState().auth.access));
  localStorage.setItem('REFRESH', JSON.stringify(store.getState().auth.refresh));
    localStorage.setItem('USER', JSON.stringify(store.getState().auth.user));})

export default store 
