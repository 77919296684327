import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateDetailDay, selectGlobalDay } from '../store/graphs/actions'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import autoBind from 'react-autobind'
import Graph from './Graph'
import {GRAPH_SIZE, NUM_PRODUCTS} from '../store/graphs/constants.js'
import './Row.css'
import * as graphSelectors from '../store/graphs/reducer'
import DayBar from '../components/DayBar'



class Row extends Component {

  constructor(props){
    super(props)
    this.state = {selectedDay: this.props.days[0]}
    autoBind(this)
  }

  componentDidUpdate(){
    if (this.state.selectedDay === undefined && this.props.days.length > 0){
      this.setState({selectedDay:this.props.days[0]})
    }
  }


   selectDay(day){
        this.props.dispatch(updateDetailDay(day.value))
        if(this.props.globalDay === null){
          this.setState({selectedDay:day.value})
        }else{
          this.props.dispatch(selectGlobalDay(day.value))
        }
    }

   render() {
    const selectedDay = this.props.globalDay !== null ? this.props.globalDay : this.state.selectedDay
      var row = []

      for (var i=0;i<this.props.graphs.length;i++){
        const id = this.props.graphs[i]
        const split = id.split('_')
        const gasString = split[2] + '_' + split[3]
        if(this.props.selectedGases.length === 0 || this.props.selectedGases.indexOf(gasString) > -1){
         row.push(<Graph key={i} id={id} currentDay={selectedDay} rowIndex={this.props.rowIndex} size={GRAPH_SIZE}/>)
        }else{
          row.push(<svg width={GRAPH_SIZE[0]} height={GRAPH_SIZE[1]} key={i} />)
        }
      }

      for (var i=row.length;i<NUM_PRODUCTS;i++){
        row.push(<svg width={GRAPH_SIZE[0]} height={GRAPH_SIZE[1]} key={i} />)
      }

      const stationString = <div className="station-label">
                            <p>{this.props.station.name} - {this.props.station.id}</p>
                            </div>

      return(<div className="row">
              {stationString}
              {row}
              <DayBar days={this.props.days} allDays={this.props.allDays} globalDay={this.props.globalDay} />
              {/*<a className="download-button" target="_blank" href={downloadLink(this.props.station.name, this.props.station.id, 's1')}>Download</a>*/}
              </div>)       
   }
}

const mapStateToProps = (state, ownProps) => {
  const graphs = graphSelectors.getGraphs(state, ownProps.rowID)
  const selectedGases = graphSelectors.getSelectedGases(state)
  const days = graphSelectors.getDays(state, ownProps.rowID)
  const allDays = graphSelectors.getAllDays(state)
  const globalDay = graphSelectors.getGlobalDay(state)

  return {
    graphs: graphs,
    selectedGases: selectedGases,
    days: days,
    globalDay: globalDay,
    allDays: allDays
  }
}

export default connect(mapStateToProps)(Row)


function downloadLink(name, id, spec){
  return 'http://ovh.pandonia-global-network.org/' + name + '/Pandora' + id + spec + '/L2_live/'
}
