import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom'
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './store'
import './index.css';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import { Q_COLORS } from './store/graphs/constants'





ReactDOM.render(
    <Provider store={store}>
    <Router>

      <Route exact path="/" component={App}/>

    </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();
