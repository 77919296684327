import React, { Component } from 'react'
import './Daybutton.css'
import Dropdown from 'react-dropdown'
import autoBind from 'react-autobind'



class DetailDaybutton extends Component {

    render(){
        const classLeft = this.props.arrows.left ? "day-arrow-left" : "day-arrow-left disabled"
        const classRight = this.props.arrows.right ? "day-arrow-right" : "day-arrow-right disabled"
        return(<div className="detail-day-select">
            <div className={classLeft} onClick={this.props.clickLeft}/>
            <span>{this.props.text}</span>
            <div className={classRight} onClick={this.props.clickRight}/>
            </div>)
    }
}

class MenuDaybutton extends Component {

    constructor(props){
        super(props)
        autoBind(this)
    }

    selectDay(day){
        this.props.selectDay(day)
    }

    render(){
        const classLeft = this.props.arrows.left ? "day-arrow-left" : "day-arrow-left disabled"
        const classRight = this.props.arrows.right ? "day-arrow-right" : "day-arrow-right disabled"
        return(<div className="menu-day-select">
            <div className={classLeft} onClick={this.props.clickLeft}/>
            <Dropdown className="menu-day-dropdown" value={this.props.globalDay} 
                                  options={this.props.allDays} onChange={this.props.selectDay}/>
            <div className={classRight} onClick={this.props.clickRight}/>
            <p>x-axes are local time</p>
            </div>)
    }
}

export {
    DetailDaybutton,
    MenuDaybutton,
}