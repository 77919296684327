import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import Collapse from 'react-collapse'
import { DetailDaybutton } from '../components/Daybutton'

/*import TransitionMotion from 'react-motion'*/
import * as graphSelectors from '../store/graphs/reducer'
import {COLORS, Q_COLORS, FILL_OPACITY, LABELS, UNITS,
        L2fit_DQ, L1_DQ, BKGRD_COLOR} from '../store/graphs/constants'

import './Detail.css'
import { scaleLinear, scaleLog, scaleThreshold, scaleBand} from 'd3-scale'
import { extent, histogram, max, bisector, range, min, median, quantile, ascending} from 'd3-array'
import { select, event as CurrentEvent, mouse as MouseEvent} from 'd3-selection'
import { line, stack } from 'd3-shape'
/*import * as layout from 'd3-shape'*/
import { axisTop, axisRight, axisLeft, axisBottom} from 'd3-axis'
import { nest, map } from 'd3-collection'
import * as d3Zoom from 'd3-zoom'
import * as d3Dispatch from 'd3-dispatch'
import { transition } from 'd3-transition'
import { interpolateNumber } from 'd3-interpolate'
import { format } from 'd3-format'



const f2d = d => isNaN(d) ? '-' : format(".2f")(d)
const f3d = d => isNaN(d) ? '-' : format(".3f")(d)
const formatExponent = d => isNaN(d) ? '-' : format(".3e")(d)
const formatTime = d => {
    var dayTime = d.slice(9,15)
    return (dayTime === undefined || d.length<2 ? d : [dayTime.slice(0,2), dayTime.slice(2,4), dayTime.slice(4,6)].join(':'))
}
const formatInteger = d=> isNaN(d) ? '-' : Math.round(d)

const margin = 30

const MARGIN = {
    top: margin,
    bottom: margin+20,
    left: margin+20,
    right: margin
}

var dispatch;

class Detail extends Component {

  constructor(props) {
    super(props)

    this.state = {isOpened: true, selectedDay: this.props.globalDay}

    this.WIDTH = this.props.size[0] - MARGIN.left - MARGIN.right
    this.HEIGHT = this.props.size[1] - MARGIN.top - MARGIN.bottom

    this.detailDaySelect = false


    autoBind(this)
  }

    render(){
        const { selectedDay, isOpened } = this.state
        const dayIndex = this.props.days.indexOf(selectedDay)
        const arrows = {left: dayIndex< this.props.days.length-1 ? true : false,
                        right: dayIndex > 0 ? true : false}
        return(
        <Collapse isOpened={isOpened} forceInitialAnimation={true} springConfig={{stiffness: 210, damping: 20}}>
        <div id="detail" className="detail">
        <svg className="detailGraph" ref={node => this.node = node}
          width={this.props.size[0]} height={this.props.size[1]}>
          </svg>
        <DetailDaybutton text={selectedDay} arrows={arrows} clickLeft={this.dayLeft} clickRight={this.dayRight}/>
        </div>
        </Collapse>)
    }

    dayLeft(){
        const dayIndex = this.props.days.indexOf(this.state.selectedDay)
        if( dayIndex < this.props.days.length-1){
            const day = this.props.days[dayIndex+1]
            this.detailDaySelect = true
            this.setState({selectedDay:day})
        }
        
    }

    dayRight(){
        const dayIndex = this.props.days.indexOf(this.state.selectedDay)
        if( dayIndex > 0){
            const day = this.props.days[dayIndex-1]
            this.detailDaySelect = true
            this.setState({selectedDay:day})
        }
    }
    componentDidMount() {
        select("svg[id='" + this.props.id + "']").classed("graph-clicked", true)
        this.createGraph()
        var toScroll = window.innerHeight - this.node.getBoundingClientRect().bottom
        window.scroll({top: window.pageYOffset-toScroll+15, left:0, behavior: 'smooth'})
    }

    componentWillUpdate(){
        select("svg[id='" + this.props.id + "']").classed("graph-clicked", false)
    }

    componentDidUpdate(){

        if(this.detailDaySelect){
            this.detailDaySelect = false
        }else{
            this.detailDaySelect = true
            //this.setState({selectedDay:this.props.globalDay})
        }

        select("svg[id='" + this.props.id + "']").classed("graph-clicked", true)
        const node = this.node
        const svg = select(node).selectAll("svg")
        svg.remove()
        select(node).select("#value-box").remove()
        this.createGraph()
        var toScroll = window.innerHeight - this.node.getBoundingClientRect().bottom
        window.scroll({top: window.pageYOffset-toScroll+15, left:0, behavior: 'smooth'})
    }

    componentWillUnmount(){
        select("svg[id='" + this.props.id + "']").classed("graph-clicked", false)
        this.setState({
            isOpened: false
        })
    }

    createGraph(){
    const node = this.node
    var svg = select(node)
    const valueKey = this.props.valueKey
    const data = this.props.data[this.state.selectedDay]
    const auxData = this.props.auxData



    var yExtent = extent(data.filter(d=>d.L2dataQflag<2), d=>d[valueKey])
    if (yExtent[0] === undefined || yExtent[0] === yExtent[1]){
      yExtent = extent(data, d => d[valueKey])
    }
    const padding = (yExtent[1] - yExtent[0])*0.1
    yExtent[0] -= padding
    yExtent[1] += padding

    this.yExtent = yExtent

    const plotWidth = 800
    dispatch = d3Dispatch.dispatch("zoomed", "hovered")

    //var wlShiftChart = wlShift().width(plotWidth/8).height(40).xOffset(600)

    var mainChart = mainPlot().width(plotWidth).height(300).yOffset(20)
                .xRange(this.props.timeRange).yRange(yExtent).valueKey(valueKey).graphLabel(this.props.label)

    var dqChart = dqPlot().width(plotWidth).height(50).yOffset(mainChart.yOffset() + mainChart.height()).xRange(this.props.timeRange)

    var uncertChart = auxPlot().width(plotWidth).height(100).yOffset(dqChart.yOffset() + dqChart.height())
                .xRange(this.props.timeRange)
                .valueKey(auxData.valueKey[0])
                .yRange(auxData.limits.uncert)
                .threshold(auxData.thresholds.uncert)
                .config({type: 'uncert', boxPlot:false})

    var fitrmsChart = auxPlot().width(plotWidth).height(100).yOffset(uncertChart.yOffset() + uncertChart.height())
                .xRange(this.props.timeRange)
                .valueKey(auxData.valueKey[1])
                .yRange(auxData.limits.fitrms)
                .threshold(auxData.thresholds.fitrms)
                .config({type: 'fitrms', boxPlot:false})

    var FWs = filterWheels().width(plotWidth).height(33).yOffset(fitrmsChart.yOffset() + fitrmsChart.height() + 5).xRange(this.props.timeRange)


    var tintChart = tintPlot().width(plotWidth).height(30).yOffset(FWs.yOffset() + FWs.height())
                .xRange(this.props.timeRange)

    const data0 = data.filter(d=> d.L2dataQflag < 1)
    var medians
    if (data0[0] !== undefined){
        medians = {1: median(data0.map(d=>d[valueKey])), 3: median(data0.map(d=>d[auxData.valueKey[0]])),
                    4: median(data0.map(d=>d[auxData.valueKey[1]])), 5: median(data0.map(d=>d[auxData.valueKey[2]]))}
    }else{
        medians = {1:'-',3:'-', 4: '-', 5:'-'}
    }
    var values = valueBox().position([920, MARGIN.top + mainChart.yOffset() - 23])
    .valueKeys([].concat(['time', valueKey, 1], auxData.valueKey.slice(0,3),['sza', 'amf'], [2], auxData.valueKey.slice(3,6)))
    .medians(medians)

    var geo = geometry().product(this.props.valueKey)

    svg.datum(data).call(mainChart)
    svg.datum(data).call(dqChart)
    svg.datum(data).call(uncertChart)
    svg.datum(data).call(fitrmsChart)
    svg.datum(data).call(FWs)
    svg.datum(data).call(tintChart)
    svg.datum(null).call(values)
    svg.datum(null).call(geo)
    }
}

const mapStateToProps = (state, ownProps) => {
  const {station, limit, timeRange, gas, prod, valueKey, data, auxData, days} = graphSelectors.getGraphParams(state, ownProps.id)
  const globalDay = graphSelectors.getGlobalDay(state)
  return {
    limit: limit,
    timeRange: timeRange,
    label: station + ': ' + gas + ' - ' + LABELS[valueKey],
    valueKey: valueKey,
    data: data,
    auxData: auxData,
    days: days,
    globalDay: globalDay
  }
}

export default connect(mapStateToProps)(Detail)

function auxPlot(){
    var width = 700
    var height = 200
    var widthBoxplots = 50
    var yRange = 0
    var xRange = 0
    var valueKey = ''
    var yOffset = 300
    var threshold = null
    var config = {type: '', boxPlot:false}
    const color = COLORS[2]
    var boxWidth = 20
	const fontSize = '11px'
    const MARGIN = {
        top: 10,
        bottom: 5,
        left: 50,
        right: 30
    }

    function plot(selection){
        selection.each(function(data, i) {
            const WIDTH = width - MARGIN.left - MARGIN.right
            const WIDTH_BOXPLOTS = widthBoxplots
            const HEIGHT = height - MARGIN.bottom - MARGIN.top

            dispatch.on("zoomed."+valueKey, zoomedX)
            dispatch.on("hovered."+valueKey, hovered)
            var zoomY = d3Zoom.zoom().on("zoom", zoomedY)

            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr("y", yOffset)
                                
            var group = svg.append("g").attr("id", "aux-plot-" + valueKey)
                    .attr("transform", "translate(" + MARGIN.left + ","+ MARGIN.top + ")")

            group.append("text")
                .attr("font-size", fontSize)
                .attr("y", -MARGIN.top/4)
				.text(LABELS[valueKey])
                //.text(config.type)

            var xScale = scaleLinear().range([0,WIDTH]).domain(xRange).nice()
            var yScale = scaleLog().range([HEIGHT, 0]).domain(yRange).nice()
            var new_xScale = xScale
            var new_yScale = yScale

            //define axes as grid        
            var xGrid = axisTop().scale(xScale).tickSize(-HEIGHT).tickFormat('').ticks(5)
            var yGrid = axisRight().scale(yScale).tickSize(-WIDTH).tickFormat('').ticks(5)
            var yAxis = axisLeft(yScale).tickSize(0).ticks(5)

            var h2 = yScale(threshold[1])
            var h1 = yScale(threshold[0]) - yScale(threshold[1])
            var h0 = HEIGHT - yScale(threshold[0])

            group.append("rect").attr("class", "bkgrd").attr("width", WIDTH).attr("height", HEIGHT)
            var bkgrdT2 = group.append("rect")
                .attr("fill", "#ebebeb")
                .attr("width",WIDTH).attr("height", h2)
            var bkgrdT1= group.append("rect")
                .attr("class","bkgrd-aux").attr("fill", COLORS[4])
                .attr("width",WIDTH).attr("height", h1)
                .attr("y", yScale(threshold[1]))
            var bkgrdT0= group.append("rect")
                .attr("class","bkgrd-aux").attr("fill", COLORS[0])
                .attr("width",WIDTH).attr("height", h0)
                .attr("y", yScale(threshold[0]))


            //ygrid
            var yGridG = group.append("g")
                    .attr("transform", "translate("+(WIDTH)+",0)")
                    .call(yGrid)
                    .attr("class","grid y")
            //call xgrid    
            var xGridG = group.append("g")
                    //.attr("transform", "translate(0," +MARGIN.top + ")")
                    .call(xGrid)
                    .attr("class","grid")

            //call yaxis
            var yAxG = group.append("g")
                    .call(yAxis)
                    .attr("class","axis y")
                    .style("font-size", "9px")
                    .attr("pointer-events", "none")

            //yGridG.selectAll("g.tick.minor").style("visibility", function(d){console.log(d); return "hidden"})

            yAxG.append("rect")
              .attr("id", "zoomy")
              .attr("width", MARGIN.left)
              .attr("height", HEIGHT)
              //.style("visibility", "hidden")
              .attr("pointer-events", "all")
              .attr("cursor", "pointer")
              .attr("transform", "translate(" + (-MARGIN.left) + ",0)")
              .call(zoomY);

            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")


            //draw datapoints
            var circles = main.selectAll("circle")
                .data(data.filter(d=> d[valueKey] > 0))
                .enter().append("circle")
                .attr("id", d=>'c'+d.time)
                .attr("class", d=> "q-" + d.L2dataQflag).attr("fill", color)
                 .attr("cx", function(d, i) { return xScale(d.loctime); })
                 .attr("cy", function(d, i) { return yScale(d[valueKey]); })
                 .attr("r", function(d, i) { return 3; })

            var focus = main.append('g').style('display', 'none');
            focus.append('circle')
                .attr('id', 'focusCircle')
                .attr('r', 4)
                .attr('class', 'focusCircle');

            const powersOfTen = [1000000.0, 100000.0, 10000.0, 1000.0, 100.0, 10.0, 1.0, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.0000001, 0.00000001]
            function update() {
                
                yAxG.call(yAxis.scale(new_yScale))
                yGridG.call(yGrid.scale(new_yScale))
                yGridG.selectAll("g.tick").style("visibility", function(d){return ((powersOfTen.indexOf(d) < 0) ? 'hidden' : "visible")})
                xGridG.call(xGrid.scale(new_xScale))

                circles.attr("cx", d=> new_xScale(d.loctime)).attr("cy", d => new_yScale(d[valueKey]))
                bkgrdT2.attr("height",new_yScale(threshold[1]))
                bkgrdT1.attr("height", new_yScale(threshold[0]) - new_yScale(threshold[1])).attr("y", new_yScale(threshold[1]))
                bkgrdT0.attr("height", HEIGHT- new_yScale(threshold[0])).attr("y", new_yScale(threshold[0]))
                //main.selectAll("line").attr("y1", new_yScale(threshold)).attr("y2", new_yScale(threshold))

            }
            function zoomedX(){
                new_xScale = CurrentEvent.transform.rescaleX(xScale)
                update()
            }
            function zoomedY(){
                new_yScale = CurrentEvent.transform.rescaleY(yScale)
                update()
            }



            function hovered(d) {

                if(d === null || d[valueKey] < 0){ focus.style('display', 'none'); return;}
                focus.style('display', null)
                focus.select("#focusCircle").attr("cx", new_xScale(d.loctime)).attr("cy", new_yScale(d[valueKey]))

            }
        });
    }

    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    };

    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    };

    plot.yRange = function(value) {
        if (!arguments.length) return yRange;
        yRange = value;
        return plot;
    };

    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.valueKey = function(value) {
        if (!arguments.length) return valueKey;
        valueKey = value;
        return plot;
    }
    plot.threshold = function(value) {
        if (!arguments.length) return threshold;
        threshold = value;
        return plot;
    }
    plot.config = function(value) {
        if (!arguments.length) return config;
        config = value;
        return plot;
    }
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    }
    
    return plot;
}

function mainPlot(){
    var width = 500
    var height = 300
    var widthHist = 100
    var graphLabel
    var yRange = 0
    var xRange = 0
    var yOffset = 0
    var valueKey = 'totcol'
    const color = Q_COLORS


    function plot(selection){
        selection.each(function(data, i) {
            const WIDTH = width - MARGIN.left - MARGIN.right
            const WIDTH_HIST = widthHist
            const HEIGHT = height - MARGIN.left - MARGIN.right

            var zoomX = d3Zoom.zoom().on("zoom", zoomedX)
            var zoomY = d3Zoom.zoom().on("zoom", zoomedY)

            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr('y', yOffset)
            var svg_hist = outer_svg.append("svg").attr('width', widthHist).attr('height', height).attr('x', width).attr('y', yOffset)

            var group = svg.append("g")
                    .attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")")


            var xScale = scaleLinear().range([0,WIDTH]).domain(xRange).nice()
            var yScale = scaleLinear().range([HEIGHT, 0]).domain(yRange).nice()
            //define axes as grid        
            var xGrid = axisTop().scale(xScale).tickSize(-HEIGHT).tickFormat('').ticks(5)
            var yGrid = axisRight().scale(yScale).tickSize(-WIDTH).tickFormat('').ticks(5)
            var xAxis = axisBottom(xScale).tickSize(0).ticks(5)
            var yAxis = axisLeft(yScale).tickSize(0).ticks(5)

                //add heading, graph label
            group.append("text")
                .attr("font-size", "16px")
                .attr("y", -15)
                .attr("class", "station-label")
                .text(graphLabel)

            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)
            .style("pointer-events", "all")
            //ygrid
            var yGridG = group.append("g")
                    .attr("transform", "translate("+(WIDTH)+",0)")
                    .call(yGrid)
                    .attr("class","grid y")
            //call xgrid    
            var xGridG = group.append("g")
                    //.attr("transform", "translate(0," +MARGIN.top + ")")
                    .call(xGrid)
                    .attr("class","grid")

            //call xaxis
            var xAxG = group.append("g")
                    .attr("transform", "translate(0," + HEIGHT + ")")
                    .call(xAxis)
                    .attr("class", "axis x")
                    .style("font-size", "12px")
                    .attr("pointer-events", "none")

            xAxG.append("rect")
              .attr("id", "zoomx")
              .attr("width", WIDTH)
              .attr("height", MARGIN.bottom)
              //.style("visibility", "hidden")
              .attr("pointer-events", "all")
              .attr("cursor", "pointer")
              .call(zoomX);

            var yAxG = group.append("g")
                    .call(yAxis)
                    .attr("class","axis y")
                    .style("font-size", "12px")
                    .attr("pointer-events", "none")

            yAxG.append("rect")
              .attr("id", "zoomy")
              .attr("width", MARGIN.left)
              .attr("height", HEIGHT)
              //.style("visibility", "hidden")
              .attr("pointer-events", "all")
              .attr("cursor", "pointer")
              .attr("transform", "translate(" + (-MARGIN.left) + ",0)")
              .call(zoomY);

            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")
                .on("dblclick", doubleClick)

            var valueline = line()
                    .defined(d => d.L2dataQflag < 1)
                    .x(d => xScale(d.loctime))
                    .y(d => yScale(d[valueKey]))

            var path = main.append("path")
                .attr("class", "line")
                .attr("d", valueline(data))
                .attr("class", "data-path-detail")
                .attr("stroke", color[0])

            //draw datapoints
            var circlesBad = main.selectAll("circlesBad")
                //.data(data.sort(function(a,b){ return b.L2dataQflag - a.L2dataQflag }))
                .data(data)
                .enter().append("circle")
                .filter(d => d.L2dataQflag > 0)
                .attr("id", d=>'c'+d.time)
                 .attr("fill", d => color[d.L2dataQflag])
                 .attr("fill-opacity", d=> FILL_OPACITY[d.L2dataQflag])
                 .attr("cx", function(d, i) { return xScale(d.loctime); })
                 .attr("cy", function(d, i) { return yScale(d[valueKey]); })
                 .attr("r", function(d, i) { return 5; })

            var circlesGood = main.selectAll("circlesGood")
                //.data(data.sort(function(a,b){ return b.L2dataQflag - a.L2dataQflag }))
                .data(data)
                .enter().append("circle")
                .filter(d => d.L2dataQflag === 0)
                .attr("id", d=>'c'+d.time)
                 .attr("fill", d => color[d.L2dataQflag])
                 .attr("fill-opacity", d=> FILL_OPACITY[d.L2dataQflag])
                 .attr("cx", function(d, i) { return xScale(d.loctime); })
                 .attr("cy", function(d, i) { return yScale(d[valueKey]); })
                 .attr("r", function(d, i) { return 5; })


            var focus = main.append('g').style('display', 'none');
            focus.append('circle')
                .attr('id', 'focusCircle')
                .attr('r', 5)
                .attr('class', 'focusCircle');


            var bisectTime = bisector(d=>d.loctime).left

            main.append('rect')
                .attr('class', 'overlay')
                .attr('width', WIDTH)
                .attr('height', HEIGHT)
                .on('mouseover', function() { focus.style('display', null); })
                .on('mouseout', function() { focus.style('display', 'none'); dispatch.call("hovered", null, null); })
                .on('mousemove', function() { 
                    var mouse = MouseEvent(this);
                    var mouseTime = new_xScale.invert(mouse[0]);
                    var i = bisectTime(data, mouseTime); // returns the index to the current data item

                    var d0 = (i-1) in data ? data[i - 1] : data[i]
                    var d1 = i in data ? data[i] : data[i-1]
                    // work out which date value is closest to the mouse
                    var d = mouseTime - d0.loctime > d1.loctime - mouseTime ? d1 : d0;

                    var x = new_xScale(d.loctime);
                    var y = new_yScale(d[valueKey]);

                    focus.select('#focusCircle')
                        .attr('cx', x)
                        .attr('cy', y);

                    dispatch.call("hovered", this, d)
                });


                
            var q0 = {key: 'hi', value: data.filter(d=>d.L2dataQflag < 1).length}
            var q1 = {key: 'med', value: data.filter(d=>d.L2dataQflag === 1).length}
            var q2 = {key: 'lo', value: data.filter(d=>d.L2dataQflag > 1).length}
            var bins = [q0,q1,q2]

            var group_hist = svg_hist.append("g")
                .attr("transform", "translate(0," + MARGIN.top + ")")

            var xScale_hist = scaleBand()
                .domain(['hi', 'med', 'lo'])
                .range([0,WIDTH_HIST])
                .padding(0.2)

            var yScale_hist = scaleLinear()
                .domain([0, data.length+1])
                .range([HEIGHT, 0])


            group_hist.append("rect").attr("class","bkgrd")
                .attr("width",WIDTH_HIST).attr("height",HEIGHT)
                .style("pointer-events", "all")
            group_hist.append("text")
                .attr("font-size", "10px").attr("font-weight", "bold")
                .attr("text-anchor", "middle")
                .attr("y", -8)
                .attr("x", WIDTH_HIST/2)
                .text("unassured quality")

            var xAxis_hist = axisBottom(xScale_hist).tickSize(0)
            var xAxG_hist = group_hist.append("g")
                    .attr("transform", "translate(0," + HEIGHT + ")")
                    .call(xAxis_hist)
                   .attr("class", "axis x")

            var bars = group_hist.selectAll(".bar")
                .data(bins)
                .enter().append("rect")
                .attr("fill", (d,i)=> color[i])
                .attr("fill-opacity", (d,i)=>(i>1 ? 0.7 : 1))
                .attr("y", d=> yScale_hist(d.value))
                .attr("x", d=> xScale_hist(d.key))
                .attr("height", d=> HEIGHT - yScale_hist(d.value))
                .attr("width", xScale_hist.bandwidth())

            const labelYOffset = -6
            var values = group_hist.selectAll(".value-text").data(bins).enter()
                            .append("text")
                            .attr("x", d=> xScale_hist(d.key) + xScale_hist.bandwidth()/2)
                            .attr("y", d=> (yScale_hist(d.value)+labelYOffset > 12 ? yScale_hist(d.value)+labelYOffset : 12) )
                            .text(d=>d.value)
                            .attr("font-size", 12).attr("text-anchor", "middle")
                        


            var new_yScale = yScale;
            var new_xScale = xScale;


            function update() {

                xAxG.call(xAxis.scale(new_xScale))
                yAxG.call(yAxis.scale(new_yScale))
                xGridG.call(xGrid.scale(new_xScale))
                yGridG.call(yGrid.scale(new_yScale))

                var valueline = line()
                    .defined(d => d.L2dataQflag === 0)
                    .x(d => new_xScale(d.loctime))
                    .y(d => new_yScale(d[valueKey]))

                circlesBad.attr("cx", d=> new_xScale(d.loctime)).attr("cy", d => new_yScale(d[valueKey]))
                circlesGood.attr("cx", d=> new_xScale(d.loctime)).attr("cy", d => new_yScale(d[valueKey]))
                path.attr("d", valueline(data))
                
            }

            function zoomedX(){
                new_xScale = CurrentEvent.transform.rescaleX(xScale)
                dispatch.apply("zoomed")
                update()
            }
            function zoomedY(){
                new_yScale = CurrentEvent.transform.rescaleY(yScale)
                update()
            }
            function doubleClick(){
                new_yScale = yScale
                new_xScale = xScale
                xAxG.select('#zoomx').call(zoomX.transform, d3Zoom.zoomIdentity)
                yAxG.select('#zoomx').call(zoomY.transform, d3Zoom.zoomIdentity)
                update()
            }

        });
    }

    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    };

    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    };
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    };

    plot.yRange = function(value) {
        if (!arguments.length) return yRange;
        yRange = value;
        return plot;
    };

    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.valueKey = function(value) {
        if (!arguments.length) return valueKey;
        valueKey = value;
        return plot;
    }
    plot.graphLabel = function(value) {
        if (!arguments.length) return graphLabel;
        graphLabel = value;
        return plot;
    }

    return plot;
}

function dqPlot(){
    var width = 800
    var height = 50
    var sideWidth = 200
    var barHeight = 5
    var squarePadding = 1
    var squareWidth = 15
    var xRange = 0
    var yOffset = 0
    var ys = [2.5, 10.5, 18.5, 26.5]
    var colors = COLORS.slice(0)
    colors.splice(0,0,'#EBEBEB')
    var keys = {L2fitDQ1: 'L2fitDQ1', L2fitDQ2: 'L2fitDQ2', L1DQ1: 'L1DQ1', L1DQ2: 'L1DQ2'}
    const fontSize = '11px'
    const xLabelOffset = -25
    const MARGIN = {
        top: 10,
        bottom: 5,
        left: 50,
        right: 30
    }

    function plot(selection){
        selection.each(function(data, i){
            const WIDTH = width - MARGIN.left - MARGIN.right
            const HEIGHT = height - MARGIN.bottom - MARGIN.top
            dispatch.on("zoomed.DQ", zoomedX)
            dispatch.on("hovered.DQ", hovered)

            var tooltip = select("body").append("div")
                .attr("class", "tooltipError")
                .style("opacity", 0);
            
            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width + sideWidth).attr('height', height).attr("y", yOffset)
                                
            var group = svg.append("g").attr("id", "aux-plot-DQ")
                    .attr("transform", "translate(" + MARGIN.left + ","+ MARGIN.top + ")")

            var sideGroup = svg.append("g").attr("id", "aux-plot-DQ-sideBar")
                    .attr("transform", "translate(" + (width) + ","+ MARGIN.top + ")")

            sideGroup.selectAll('.labels').data([0,1,2,3,4,5,6,7,8]).enter()
                .append("text").attr("font-size", "8px").attr("y", ys[3]+12).attr("x", d => (squareWidth+squarePadding*2)*d + squareWidth/2)
                .text(d=>d)

            var err1 = sideGroup.selectAll('.err1').data([0,1,2,3]).enter()
                                .append("rect").attr("height", barHeight).attr("width", (squareWidth))
                                .attr("fill",BKGRD_COLOR).attr("y", ys[0]).attr("x", d=>(squareWidth+squarePadding*2)*d + squarePadding)
                                
            var err2 = sideGroup.selectAll('.err2').data([0,1,2,3]).enter()
                                .append("rect").attr("height", barHeight).attr("width", (squareWidth))
                                .attr("fill",BKGRD_COLOR).attr("y", ys[1]).attr("x", d=>(squareWidth+squarePadding*2)*d + squarePadding)

            var err3 = sideGroup.selectAll('.err3').data([0,1,2,3,4,5,6,7,8]).enter()
                                .append("rect").attr("height", barHeight).attr("width", (squareWidth))
                                .attr("fill",BKGRD_COLOR).attr("y", ys[2]).attr("x", d=>(squareWidth+squarePadding*2)*d + squarePadding)

            var err4 = sideGroup.selectAll('.err3').data([0,1,2,3,4,5,6,7,8]).enter()
                                .append("rect").attr("height", barHeight).attr("width", (squareWidth))
                                .attr("fill",BKGRD_COLOR).attr("y", ys[3]).attr("x", d=>(squareWidth+squarePadding*2)*d + squarePadding)
            
            err1.on('mouseover', d=> {tooltip.transition().style("opacity", 0.9)
                                     tooltip.html("<p>" + L2fit_DQ[d] + "</p>").style("left", CurrentEvent.pageX + "px").style("top", CurrentEvent.pageY + "px")})
                .on('mouseout', d=> tooltip.transition().style("opacity", 0))
            err2.on('mouseover', d=> {tooltip.transition().style("opacity", 0.9)
                                     tooltip.html("<p>" + L2fit_DQ[d] + "</p>").style("left", CurrentEvent.pageX + "px").style("top", CurrentEvent.pageY + "px")})
                .on('mouseout', d=> tooltip.transition().style("opacity", 0))
            err3.on('mouseover', d=> {tooltip.transition().style("opacity", 0.9)
                                     tooltip.html("<p>" + L1_DQ[d] + "</p>").style("left", CurrentEvent.pageX + "px").style("top", CurrentEvent.pageY + "px")})
                .on('mouseout', d=> tooltip.transition().style("opacity", 0))
            err4.on('mouseover', d=> {tooltip.transition().style("opacity", 0.9)
                                     tooltip.html("<p>" + L1_DQ[d] + "</p>").style("left", CurrentEvent.pageX + "px").style("top", CurrentEvent.pageY + "px")})
                .on('mouseout', d=> tooltip.transition().style("opacity", 0))

           /* err1.append("title").text(d => L2fit_DQ[d])
            err2.append("title").text(d => L2fit_DQ[d])
            err3.append("title").text(d => L1_DQ[d])
            err4.append("title").text(d => L1_DQ[d])*/

            group.append("text")
                .attr("font-size", fontSize)
                .attr("y", -MARGIN.top/4)
                .text("DQs")

            group.append("text").attr("font-size", "8px").attr("y", 6).attr("x", xLabelOffset).text("L2Q1")
            group.append("text").attr("font-size", "8px").attr("y", 16).attr("x", xLabelOffset).text("L2Q2")
            group.append("text").attr("font-size", "8px").attr("y", 26).attr("x", xLabelOffset).text("L1Q1")
            group.append("text").attr("font-size", "8px").attr("y", 36).attr("x", xLabelOffset).text("L1Q2")

            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)

            var xScale = scaleLinear().range([0,WIDTH]).domain(xRange).nice()
            var new_xScale = xScale

            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")

            var rects1 = main.selectAll(".L2DQ1").data(getChangeIntervals(data, keys.L2fitDQ1))
                        .enter().append("rect")
                        .attr("fill", d=> (colors[vectorSum(d.x0[keys.L2fitDQ1])] ))
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 2.5)

            var rects2 = main.selectAll(".L2DQ2").data(getChangeIntervals(data, keys.L2fitDQ2))
                        .enter().append("rect")
                        .attr("fill", d=> colors[vectorSum(d.x0[keys.L2fitDQ2])])
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 10.5)

            var rects3 = main.selectAll(".L1DQ1").data(getChangeIntervals(data, keys.L1DQ1))
                        .enter().append("rect")
                        .attr("fill", d=> (colors[vectorSum(d.x0[keys.L1DQ1])] ))
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 18.5)

            var rects4 = main.selectAll(".L1DQ2").data(getChangeIntervals(data, keys.L1DQ2))
                        .enter().append("rect")
                        .attr("fill", d=> colors[vectorSum(d.x0[keys.L1DQ2])])
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 26.5)

            var focus = group.append('g').style('display', 'none');
            focus.append('rect')
                .attr('id', 'focus-rect')
                .attr("height", 30).attr("width", 6)
                .attr("y", 2.5)
                .attr('class', 'focus-rect');

            function zoomedX(){

                new_xScale = CurrentEvent.transform.rescaleX(xScale)
                rects1.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
                rects2.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
                rects3.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
                rects4.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
            }

            function hovered(data) {

                if(data === null){
                 focus.style('display', 'none'); sideGroup.selectAll("rect").attr("fill", BKGRD_COLOR); return;
                }
                err1.attr("fill", d => (toDQVector(data[keys.L2fitDQ1])[d] === '1' ? colors[1] : BKGRD_COLOR))
                err2.attr("fill", d => (toDQVector(data[keys.L2fitDQ2])[d] === '1' ? colors[1] : BKGRD_COLOR))
                err3.attr("fill", d => (toDQVector(data[keys.L1DQ1])[d] === '1' ? colors[1] : BKGRD_COLOR))
                err4.attr("fill", d => (toDQVector(data[keys.L1DQ2])[d] === '1' ? colors[1] : BKGRD_COLOR))
                focus.style('display', null)
                focus.select("#focus-rect").attr("x", new_xScale(data.loctime)-3)

            }

        })
    }
    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    }
    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    }
    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    }
    return plot
}

function filterWheels(){
    var width = 800
    var height = 50
    var xRange = 0
    var yOffset = 0
    var colors = COLORS.slice(0).reverse()
    var keys = {FW1: 'FW1', FW2: 'FW2'}
	const fontSize = '11px'
    const MARGIN = {
        top: 10,
        bottom: 5,
        left: 50,
        right: 30
    }

    function plot(selection){
        selection.each(function(data, i){
            const WIDTH = width - MARGIN.left - MARGIN.right
            const HEIGHT = height - MARGIN.bottom - MARGIN.top
            dispatch.on("zoomed.FW", zoomedX)
            dispatch.on("hovered.FW", hovered)

            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr("y", yOffset)
                                
            var group = svg.append("g").attr("id", "aux-plot-FW")
                    .attr("transform", "translate(" + MARGIN.left + ","+ MARGIN.top + ")")

            group.append("text")
                .attr("font-size", fontSize)
                .attr("y", -MARGIN.top/4)
                .text("FWs")

            group.append("text").attr("font-size", "8px").attr("y", 6).attr("x", -8).text("1")
            group.append("text").attr("font-size", "8px").attr("y", 16).attr("x", -8).text("2")

            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)

            var xScale = scaleLinear().range([0,WIDTH]).domain(xRange).nice()
            var new_xScale = xScale

            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")

            var rects1 = main.selectAll(".FW1").data(getChangeIntervals(data, keys.FW1))
                        .enter().append("rect")
                        .attr("fill", d=> colors[d.x0[keys.FW1]-1])
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 2.5)

            var rects2 = main.selectAll(".FW2").data(getChangeIntervals(data, keys.FW2))
                        .enter().append("rect")
                        .attr("fill", d=> colors[d.x0[keys.FW2]-1])
                        .attr("height", 5)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 10.5)

            var focus = group.append('g').style('display', 'none');
            focus.append('rect')
                .attr('id', 'focus-rect')
                .attr("height", 12).attr("width", 6)
                .attr("y", 2.5)
                .attr('class', 'focus-rect');

            function zoomedX(){

                new_xScale = CurrentEvent.transform.rescaleX(xScale)
                rects1.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
                rects2.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
            }

            function hovered(d) {

                if(d === null){ focus.style('display', 'none'); return;}
                focus.style('display', null)
                focus.select("#focus-rect").attr("x", new_xScale(d.loctime)-3)

            }

        })
    }
    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    }
    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    }
    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    }
    return plot
}

function tintPlot(){
    var width = 800
    var height = 50
    var xRange = 0
    var yOffset = 0
    //var colors = ["#A32540","#CC41A9","#BB78ED","#59AFFF","#00D2EC","#00E0B5","#65DD6B","#C7CE16","#FFB953"]
    var colors = COLORS.slice(0)
    var thresholds = [32.5, 75.0, 125.0, 250.0, 500.0, 1000.0, 2000.0, 4000.0]
    var key = 'Tint'
	const fontSize = '11px'
    const MARGIN = {
        top: 10,
        bottom: 5,
        left: 50,
        right: 30
    }

    function plot(selection){
        selection.each(function(data, i){
            const WIDTH = width - MARGIN.left - MARGIN.right
            const HEIGHT = height - MARGIN.bottom - MARGIN.top
            dispatch.on("zoomed.tint", zoomedX)
            dispatch.on("hovered.tint", hovered)

            var outer_svg = select(this)
            var svg = outer_svg.append("svg").attr('width', width).attr('height', height).attr("y", yOffset)
                                

            var group = svg.append("g").attr("id", "aux-plot-tint")
                    .attr("transform", `translate(${MARGIN.left},${MARGIN.top})`)

            group.append("text")
                .attr("font-size", fontSize)
                .attr("y", -MARGIN.top/4)
				.text(LABELS[key])
                //.text("Tint")

            group.append("rect").attr("class","bkgrd")
            .attr("width",WIDTH).attr("height",HEIGHT)

            var xScale = scaleLinear().range([0,WIDTH]).domain(xRange).nice()
            var new_xScale = xScale
            var yScale = scaleThreshold().range(range(0,8)).domain(thresholds)

            data = data.map(function(d){return {Tint:yScale(d[key]), loctime: d.loctime}})

            group.append("defs").append("clipPath")
                .attr("id", "clipDetail")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", WIDTH)
                .attr("height", HEIGHT)

            const main = group.append("g")
                .attr("clip-path", "url(#clipDetail)")

            var rects = main.selectAll(".Tint").data(getChangeIntervals(data, key))
                        .enter().append("rect")
                        .attr("fill", d=> colors[d.x0[key]])
                        .attr("height", 10)
                        .attr("width", d=> xScale(d.x1) - xScale(d.x0.loctime))
                        .attr("x", d=> xScale(d.x0.loctime))
                        .attr("y", 2.5)

            var focus = group.append('g').style('display', 'none');
            focus.append('rect')
                .attr('id', 'focus-rect')
                .attr("height", 10).attr("width", 6)
                .attr("y", 2.5)
                .attr('class', 'focus-rect');

            function zoomedX(){
                new_xScale = CurrentEvent.transform.rescaleX(xScale)
                rects.attr("width", d=> new_xScale(d.x1) - new_xScale(d.x0.loctime))
                        .attr("x", d=> new_xScale(d.x0.loctime))
            }

            function hovered(d) {
                if(d === null){ focus.style('display', 'none'); return;}
                focus.style('display', null)
                focus.select("#focus-rect").attr("x", new_xScale(d.loctime)-3)
            }
        })
    }

    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    }
    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    }
    plot.xRange = function(value) {
        if (!arguments.length) return xRange;
        xRange = value;
        return plot;
    }
    plot.yOffset = function(value) {
        if (!arguments.length) return yOffset;
        yOffset = value;
        return plot;
    }
    return plot
}

function valueBox(){
    var height = 500
    var width = 300
    var position = [0,0]
    var valueKeys = []
    var medianKeys = [1,3,4,5]
    var format = [d=>formatTime(d), d=>formatExponent(d), d=>d,
                    d=>formatExponent(d), d=>formatExponent(d), d=>formatExponent(d), d=>f2d(d),
                     d=>f3d(d), d=>d, d=>f3d(d), d=>d, d=>d]
    var medians = []
    var columns = ['parameter',' ', 'value', 'median']

    function plot(selection){
        selection.each(function(data, i){

        dispatch.on("hovered.valueBox", update)
        
        if(data === null || data === undefined){
            data = {}
            valueKeys.forEach(function(d){
                data[d] = Number.isInteger(d) ? ' ' : '-'
            })
        }
        var wrapper = select(this).append("foreignObject").attr("width", width).attr("height", height).attr("id", "value-box")
                        .attr("transform", "translate(" + position[0] + "," + position[1] + ")").append("xhtml:body")
        var table = wrapper.append("xhtml:table").attr("transform", "translate(" + position[0] + "," + position[1] + ")")
                            .style("table-layout", "fixed").style("width", "300px")
        table.append("xhtml:col").attr("width", "100px")
        table.append("xhtml:col").attr("width", "40px").attr("align", "left")
        table.append("xhtml:col").attr("width", "90px")
        table.append("xhtml:col").attr("width", "70px")

        var tHeader = table.append("xhtml:thead").append("xhtml:tr").selectAll("td").data(columns).enter()
                    .append("xhtml:td").text(d=>d)

        var tBody = table.append("xhtml:tbody")

        var rows = tBody.selectAll("tr").data(valueKeys).enter().append("xhtml:tr")

        rows.selectAll("td").data((d,i) => medianKeys.indexOf(i) > -1 ? [LABELS[d], UNITS[d], data[d], formatExponent(medians[i])] : [LABELS[d], UNITS[d], data[d]]).enter()
            .append("xhtml:td").text(d=>Number.isInteger(d) ? ' ' : d)
            .classed("value-text", (d,i) => i === 2)

        function update(data){
            if(data === null){
                data = {}
                valueKeys.forEach(function(d){
                    data[d] = Number.isInteger(d) ? ' ' : '-'
                })
            }
            tBody.selectAll("tr").each(function(d,i){
                var str
                if(Number.isInteger(d)) {
                    str = ' '
                }else {
                    str = format[i](data[valueKeys[i]])
                    
                }
                select(this).selectAll(".value-text").text(str)

            })
        }
        })
    }
    plot.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        return plot;
    }
    plot.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        return plot;
    }
    plot.position = function(value) {
        if (!arguments.length) return position;
        position = value;
        return plot;
    }
    plot.valueKeys = function(value) {
        if (!arguments.length) return valueKeys;
        valueKeys = value;
        return plot;
    }
    plot.medians = function(value) {
        if (!arguments.length) return medians;
        medians = value;
        return plot;
    }


    return plot;
}

function geometry(){
    var width = 300
    var height = 300
    var position = [800, 300]
    var valuekey = 'saa'
    var product = 'totcol'
    var radius = 15
    var maxAMF = 4
    var fontSize = "12px"
    var letterPadding = 8

    function plot(selection){
        selection.each(function(data, i){

            dispatch.on("hovered.geo", update)

            var svg = select(this).append("svg").attr("width", width).attr("height", height)
                        .attr("x", position[0]).attr("y", position[1])
            var center = svg.append("g")
                    .attr("transform", `translate(${width/2},${height/2})`)

            center.append("circle").attr("cx", 0).attr("cy", 0).attr("fill", "none")
                .attr("r", radius*maxAMF).attr("stroke-width", 1).attr("stroke", "grey")

            center.append("line").attr("x1",0).attr("y1",radius*maxAMF)
                .attr("x2", 0).attr("y2", -radius*maxAMF).attr("stroke-width", 1).attr("stroke", "grey")

            center.append("line").attr("y1",0).attr("x1",radius*maxAMF)
                .attr("y2", 0).attr("x2", -radius*maxAMF).attr("stroke-width", 1).attr("stroke", "grey")


            center.append("text").attr("y", -radius*maxAMF - letterPadding).text("N")
            center.append("text").attr("y", radius*maxAMF +letterPadding).text("S")
            center.append("text").attr("x", -radius*maxAMF - letterPadding).text("W")
            center.append("text").attr("x", radius*maxAMF + letterPadding).text("E")

            center.selectAll("text").attr("font-size", fontSize).attr("text-anchor", "middle").attr("dominant-baseline", "central")

            var sun = center.append("line").attr("x1",0).attr("y1",0)
                .attr("x2", 0).attr("y2", -radius).attr("stroke-width", 4).attr("stroke", "lightgrey")
                .style("visibility", "hidden")

            var viewing = center.append("line").attr("x1",0).attr("y1",0)
                .attr("x2", 0).attr("y2", -radius).attr("stroke-width", 4).attr("stroke", Q_COLORS[0])
                .style("visibility", "hidden")

            if(product !== 'totcol'){
                viewing.style("opacity", 0)
            }


            function update(data){
                if(data === null || Math.abs(data['sza']) > 90){
                    viewing.style("visibility", "hidden")
                    sun.style("visibility", "hidden")
                    return
                }
                var amf = data['amf'] < maxAMF ? data['amf'] : maxAMF

                viewing.attr("x2", amf * radius * Math.sin(toRadians(data[valuekey])))
                    .attr("y2", amf * -radius * Math.cos(toRadians(data[valuekey])))

                viewing.style("visibility", "visible")

                sun.attr("x2", maxAMF * radius * Math.sin(toRadians(data[valuekey])))
                    .attr("y2", maxAMF * -radius * Math.cos(toRadians(data[valuekey])))

                sun.style("visibility", "visible")
                
            }

        })
    }

    plot.product = function(value) {
        if (!arguments.length) return product;
        product = value;
        return plot;
    }

    return plot;
}


function getChangeIntervals(data, key){
    var prev = data[0];
    var points = [prev];
    data.forEach(function(d,i){
        if(prev[key] !== d[key]){
            points.push(d)
            prev = d
        }
    })
    points.push(data[data.length-1])

    var intervals = []

    for (var i=0;i<points.length-1;i++){
        intervals.push({x0: points[i], x1:points[i+1].loctime})
    }

    return intervals
}

function toRadians(degree){
    return degree * (Math.PI / 180)
}

const toDQVector = number => (number.toString(2).split("").reverse().join(""))
const vectorSum = number => {
    const vec = toDQVector(number)
    var sum = 0
    for(var i=0; i < vec.length; i++){
        sum += Number(vec.charAt(i))
    }
    return sum
}







